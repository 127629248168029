import React, { Component } from 'react';
import './style.scss'
import Helmet from "react-helmet"


class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        items: []
      };
    }
  
    componentDidMount() {
      fetch('https://raw.githubusercontent.com/heyteacher/COVID-19/master/dati-json/dpc-covid19-ita-andamento-nazionale-latest.json')
        .then(res => res.json())
        .then(result => {
          this.setState({
            items: result
          });
        });
    }
  
    render() {
        const { items } = this.state;
        return (
          
    <section class="section">
    {items.map(item => (
        <div class="tile is-parent is-vertical">
                        <Helmet>
                            <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_nazionale.js"></script>
                            <script src="https://romano.fun/static/grafico_dettaglio.js"></script>
                        </Helmet>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(item.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(item.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(item.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(item.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(item.deceduti)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(item.nuovi_deceduti)}</b>
                </p>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(item.dimessi_guariti)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(item.nuovi_dimessi_guariti)}</b>
                </p>
                <div id="grafico_nazionale" style={{ width: "100%", height: "500px" }}></div>
                <h1 class="title">TERAPIA INTENSIVA: <b>{new Intl.NumberFormat('it-IT').format(item.terapia_intensiva)}</b></h1>
                <p className="subtitle is-size-5">
                <b>{new Intl.NumberFormat('it-IT').format(item.nuovi_terapia_intensiva)}</b>
                </p>
                <h1 class="title">TAMPONI: <b>{new Intl.NumberFormat('it-IT').format(item.tamponi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(item.nuovi_tamponi)}</b>
                </p>
                <h1 class="title">CASI TESTATI: <b>{new Intl.NumberFormat('it-IT').format(item.casi_testati)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(item.nuovi_casi_testati)}</b>
                </p>
                <div id="grafico_dettagli" style={{ width: "100%", height: "500px" }}></div>
                <h1 class="title">OSPEDALIZZATI: <b>{new Intl.NumberFormat('it-IT').format(item.totale_ospedalizzati)}</b></h1>
                <h1 class="title">RICOVERATI CON SINTOMI: <b>{new Intl.NumberFormat('it-IT').format(item.ricoverati_con_sintomi)}</b></h1>
                <h1 class="title">ISOLAMENTO DOMICILIARE: <b>{new Intl.NumberFormat('it-IT').format(item.isolamento_domiciliare)}</b></h1>
            </div>
        </div>
        )      
    )
    }
    </section>
    )}
}

export default App;