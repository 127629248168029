import React, { Component } from 'react';
import './style.scss'
// import './covid19-prova.css'
import Helmet from "react-helmet"

class App_regioni extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        items: []
      };
    }
  
    componentDidMount() {
      fetch ('https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-regioni-latest.json')
        .then(res => res.json())
        .then(result => {
          this.setState({
            items: result
          });
        });
    }
  
    render() {
        const { items } = this.state;
        return (
          
    <section class="section">


    {items.filter(regione => regione.codice_regione === 3).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                            <link rel="stylesheet" type="text/css" href="https://romano.fun/static/covid19-prova.css"></link>
                            <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_lombardia.js"></script>
                        </Helmet> */}
             <h1 class="title">LOMBARDIA</h1>
             <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_lombardia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }

{items.filter(regione => regione.codice_regione === 13).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                        <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_abruzzo.js"></script>
                        </Helmet> */}
             <h1 class="title">ABRUZZO</h1>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_puglia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }

{items.filter(regione => regione.codice_regione === 17).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                        <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_basilicata.js"></script>
                        </Helmet> */}
             <h1 class="title">BASILICATA</h1>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_puglia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }

{items.filter(regione => regione.codice_regione === 15).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                        <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_campania.js"></script>
                        </Helmet> */}
             <h1 class="title">CAMPANIA</h1>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_puglia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }

{items.filter(regione => regione.codice_regione === 14).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                        <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_molise.js"></script>
                        </Helmet> */}
             <h1 class="title">MOLISE</h1>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_puglia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }
 {items.filter(regione => regione.codice_regione === 16).map(filteredRegione => (
        <div class="tile is-parent is-vertical">
                        {/* <Helmet>
                        <script src="//www.amcharts.com/lib/4/core.js"></script>
                            <script src="//www.amcharts.com/lib/4/charts.js"></script>
                            <script src="//www.amcharts.com/lib/4/themes/animated.js"></script>
                            <script src="https://romano.fun/static/grafico_puglia.js"></script>
                        </Helmet> */}
             <h1 class="title">PUGLIA</h1>
            <div class="tile is-child notification">
            <h1 class="title">TOTALE CASI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_casi)}</b></h1>
                <p className="subtitle is-size-5">
                <b>+{new Intl.NumberFormat('it-IT').format(filteredRegione.nuovi_positivi)}</b>
                </p>
                <h1 class="title">ATTUALMENTE POSITIVI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_positivi)}</b></h1>
                <p className="subtitle is-size-5">
                <b> {new Intl.NumberFormat('it-IT').format(filteredRegione.variazione_totale_positivi)}</b> 
                </p>
                <h1 class="title">MORTI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.deceduti)}</b></h1>
                <h1 class="title">GUARITI: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.dimessi_guariti)}</b></h1>
                {/* <div id="grafico_puglia" style={{ width: "100%", height: "500px" }}></div> */}
                <p className="subtitle is-size-5">Terapia intensiva: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.terapia_intensiva)}</b></p>
                <p className="subtitle is-size-5">Tamponi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.tamponi)}</b></p>
                <p className="subtitle is-size-5">Casi testati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.casi_testati)}</b></p>
                <p className="subtitle is-size-5">Ospedalizzati: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.totale_ospedalizzati)}</b></p>
                <p className="subtitle is-size-5">Ricoverati con sitnomi: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.ricoverati_con_sintomi)}</b></p>
                <p className="subtitle is-size-5">Isolamento domiciliare: <b>{new Intl.NumberFormat('it-IT').format(filteredRegione.isolamento_domiciliare)}</b></p>
            </div>
        </div>
        )      
    )
    }

    </section>
    )}
}

export default App_regioni;