import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Daticovid2 from './dati_covid_tiles';
import App from './app';
import App_regioni from './app_regioni';
import Footer from './footer';

// import App_abruzzo from './app_abruzzo';
// import App_basilicata from './app_basilicata';
// import App_campania from './app_campania';
// import App_molise from './app_molise';
// import App_puglia from './app_puglia';

const Layout = ({ children }) => (
	<div>
		<Header />
		<App />
		<App_regioni />
		{/* <App_abruzzo />
		<App_basilicata />
		<App_campania />
		<App_molise />
		<App_puglia /> */}
		<Daticovid2 />
		<Footer />
	</div>
);

export default Layout;
