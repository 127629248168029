import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';
import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/covid19-romano-logo.png';

const Navbar = () => (
	<div className="hero-head">
		<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="https://romano.fun/virus">
	<img src={bulmaLogo} alt="Prova" /></a>

    
  		</div>
		</nav>
	</div>
);

export default Navbar;
