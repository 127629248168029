import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';

import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section class="hero gradientBg">
		<Navbar />
		<div class="hero-body">
    <div class="container">
      <h1 class="title">
      Emergenza COVID-19
      </h1>
      <h2 class="subtitle">
      Numeri e grafici
      </h2>
    </div>
  </div>
</section>
);
  

export default Header;
